const BlogList = [
  {
    id: 1,
    dateposted: '05 March, 2021',
    title: '10 Top Software Development Tools You Should Use In 2021',
    image:
      'https://hackr.io/blog/top-software-development-tools/thumbnail/large',
    link: '',
    content:
      'The software industry is one of the most wide-reaching and ever-growing industries. In today’s era, the use of software or applications has increased more extensively than ever before.',
  },
  {
    id: 2,
    dateposted: '11 April, 2021',
    title: 'Best Wireframe Tools for UI/UX Designers in 2021',
    image: 'https://hackr.io/blog/best-wireframe-tools/thumbnail/large',
    link: '',
    content:
      'Wireframe tools are ideal for mobile and web apps, dashboards, interfaces, and website design. A wireframe is the skeletal framework of a website; tools help in the visualization of this framework. In a project, UI/UX designers, business analysts, visual designers, and senior developers create wireframes.',
  },
  {
    id: 3,
    dateposted: '27 May, 2021',
    title: 'How to learn SQL (step-by-step guide) in 2021',
    image: 'https://hackr.io/blog/how-to-learn-sql/thumbnail/large',
    link: '',
    content:
      'With the gradual shift in digital technology in recent years, trillions of bytes of data are being generated every second. It has been rightfully said by some of the experts that - “Data is the new oil!”. The data generated by the users come in various forms - these are generated through social media browsing, binge-watching movies, shopping through online portals, online banking transactions, etc.',
  },
  {
    id: 4,
    dateposted: '23 Jun, 2021',
    title: 'Best CSS Frameworks for Front-End Developers in 2021',
    image: 'https://hackr.io/blog/best-css-frameworks/thumbnail/large',
    link: '',
    content:
      'Cascading Style Sheets, known more popularly as CSS, give styles to your pages or HTML elements. You can control the layout of multiple web pages with a single style element or write custom CSS for different elements.',
  },
  {
    id: 5,
    title: '10 Best Web Development Projects You Should Know in 2021',
    dateposted: '08 Jul, 2021',
    image:
      'https://hackr.io/blog/best-web-development-projects/thumbnail/large',
    link: '',
    content:
      'Humanity has come a long way when it comes to developing the internet and the force behind making it accessible in a convenient way, i.e., web development.',
  },
  {
    id: 6,
    title: '10 Best React Courses to Learn in 2021',
    dateposted: '01 Aug, 2021',
    image: 'https://hackr.io/blog/react-courses/thumbnail/large',
    link: '',
    content:
      'React is turning heads worldwide as one of the most popularly used libraries in JavaScript. Developers who are adept at using React and Redux for programming are experiencing an all-time high demand.  We have compiled a list of popular and best courses that will help you meet the demand head-on and secure your future exponentially. ',
  },
]

export default BlogList
